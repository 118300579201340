<template>
  <div>
    <div v-if="exemptSbrhvi">
      <v-alert class="mt-10" type="info">
        {{ $t("text.producer_with_exempt_sbrhvi") }} <br />
      </v-alert>
    </div>

    <div v-if="canSeeSBRHVIInvite">
      <v-alert
        v-if="canInviteSbrhviResponsible && !showSelectProducerOrGroup"
        class="mt-3"
        type="info"
      >
        {{ $t("text.informativo_possui_responsavel_sbrhvi.0") }} <br />
        {{ $t("text.informativo_possui_responsavel_sbrhvi.1") }}
      </v-alert>

      <div class="my-10">
        <select-producer-or-group v-if="showSelectProducerOrGroup" />
      </div>

      <selected-producer-or-group />

      <div v-if="canUpdateResponsible" class="mb-4">
        <p class="section-subtitle">
          {{ $t("text.selecionar_responsavel_sbrhvi") }}
        </p>
        <v-btn
          elevation="1"
          color="primary"
          class="px-4"
          @click="handleChangeResponsible()"
        >
          {{ btnAddOrChangeSbrhviResponsibleLabel }}
        </v-btn>
      </div>

      <note
        v-if="hasPreviousSbrhviResponsible && hasSelectedProducerOrGroup"
        class="my-8"
        >{{ $t("text.informativo_possui_responsavel_sbrhvi_sistema") }}</note
      >

      <div v-if="hasSelectedProducerOrGroup && users && users.length">
        <p class="section-subtitle">
          {{ $t("text.responsavel_lider_sbrhvi") }}
        </p>
        <div>
          <table-responsibles
            :responsibles="users"
            :canRemove="!sbrHviResponsibles.length"
            :headers="sbrhviUsersHeaders"
            @removeResponsible="removeUser($event)"
            @editResponsible="editUser($event)"
          />

          <div class="mt-10">
            <table-other-responsibles />
          </div>
        </div>
      </div>

      <dialog-invite-user
        :dialogActivate="dialogInviteUsers"
        @onCloseDialog="onCancelDialogInviteUser"
        @inviteUser="confirmInsertUser"
      />
    </div>
  </div>
</template>
<script>
import SelectProducerOrGroup from "@/modules/invite/views/components/SelectProducerOrGroup";
import SelectedProducerOrGroup from "@/modules/invite/views/components/SelectedProducerOrGroup";
import DialogInviteUser from "./DialogInviteUser";
import Note from "@/modules/core/views/components/Note";
import TableResponsibles from "@/modules/invite/views/components/TableResponsibles";
import TableOtherResponsibles from "@/modules/invite/views/components/TableOtherResponsibles";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    showSelectProducerOrGroup: {
      type: Boolean,
      required: true
    }
  },
  components: {
    SelectProducerOrGroup,
    SelectedProducerOrGroup,
    DialogInviteUser,
    TableResponsibles,
    Note,
    TableOtherResponsibles
  },
  data: () => ({
    dialogInviteUsers: {
      value: false
    }
  }),

  computed: {
    ...mapGetters({
      selectedSystems: "invite/getSystems",
      sbrhviUser: "invite/getSbrHviUser",
      canInviteSbrhviResponsible: "invite/getCanInviteSbrhviResponsible",
      exemptSbrhvi: "invite/getExemptSbrhvi",
      hasPreviousSbrhviResponsible: "invite/getHasSbrHviResponsible",
      hasSelectedProducerOrGroup: "invite/getHasProducerOrGroupSelected",
      sbrHviResponsibles: "invite/getSbrHviResponsibles"
    }),

    users() {
      if (!this.sbrhviUser || !this.sbrhviUser.nome) {
        return [];
      }

      return [this.sbrhviUser];
    },

    sbrhviUsersHeaders() {
      const headers = [
        { text: "Nome", value: "nome" },
        { text: "CPF", value: "cpf" },
        { text: "E-Mail", value: "email" },
        { text: "Telefone Fixo", value: "telefone" },
        { text: "Celular", value: "celular" }
      ];

      if (
        !this.hasPreviousSbrhviResponsible ||
        this.canInviteSbrhviResponsible
      ) {
        return [...headers, { value: "acao" }];
      }
      return headers;
    },

    canUpdateResponsible() {
      return (
        (!this.hasPreviousSbrhviResponsible ||
          this.canInviteSbrhviResponsible) &&
        this.hasSelectedProducerOrGroup
      );
    },

    btnAddOrChangeSbrhviResponsibleLabel() {
      return this.sbrhviUser.id
        ? this.$t("text.alterar_responsavel_lider")
        : this.$t("text.adicionar_responsavel_lider");
    },

    canSeeSBRHVIInvite() {
      return !this.exemptSbrhvi || this.selectedSystems.length === 1;
    }
  },
  methods: {
    ...mapActions("invite", ["setSbrHviUser", "removeSbrHviUser"]),
    ...mapActions(["toggleSnackbar"]),

    handleChangeResponsible() {
      this.dialogInviteUsers.isEdit = false;
      this.dialogInviteUsers.value = true;
      this.dialogInviteUsers.initialData = {};
    },
    async onUpdateUser(initialData) {
      this.dialogInviteUsers.initialData = await initialData;
      this.dialogInviteUsers.isEdit = true;
      this.dialogInviteUsers.value = true;
    },
    onCancelDialogInviteUser() {
      this.dialogInviteUsers.value = false;
      this.dialogInviteUsers.isEdit = false;
    },
    removeUser(user) {
      this.removeSbrHviUser(user);
    },
    editUser(data) {
      this.onUpdateUser({ ...data });
    },
    confirmInsertUser(payload) {
      const { data, isEdit } = payload;
      this.dialogInviteUsers.value = false;

      this.setSbrHviUser(data);

      const action = isEdit ? "alterado" : "adicionado";
      this.toggleSnackbar({
        text: `Responsável ${action} com sucesso`,
        type: "success"
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
