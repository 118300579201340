<template>
  <div>
    <span class="subtitle-2 grey--text">{{
      $t("text.produtor_grupo_selecionado")
    }}</span>
    <h3 class="mb-4 mt-0">
      {{ text }}
    </h3>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      newInvite: "invite/getNewInvite"
    }),
    text() {
      return this.newInvite.produtor?.nome
        ? this.newInvite.produtor.nome
        : this.$t("text.nenhum_registro_selecionado");
    }
  }
};
</script>
<style></style>
