<template>
  <v-data-table
    :hide-default-footer="true"
    :headers="headers"
    :items="responsibles"
    :items-per-page="1000"
    class="bordered-table"
    :no-data-text="$t('text.nenhum_registro_selecionado_tabelas')"
  >
    <template v-slot:[`item.acao`]="{ item }">
      <v-row>
        <v-col v-if="canRemove" cols="5"
          ><v-btn
            small
            text
            color="primary"
            @click="$emit('removeResponsible', item)"
            >Remover</v-btn
          ></v-col
        >
        <v-col cols="7"
          ><v-btn
            small
            text
            color="primary"
            @click="$emit('editResponsible', item)"
            >Editar dados</v-btn
          ></v-col
        >
      </v-row>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    responsibles: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    canRemove: {
      type: Boolean,
      required: false,
      default: () => true
    }
  }
};
</script>
<style></style>
