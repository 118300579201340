<template>
  <div>
    <v-dialog
      v-model="state.activate"
      persistent
      origin="center left"
      width="850"
    >
      <v-card>
        <v-card-title class="primary--text"
          >{{ $t("labels.select_producer_group") }}
        </v-card-title>

        <v-divider></v-divider>

        <div class="mx-3">
          <v-row class="ma-0 mt-1">
            <v-col cols="12" md="3">
              <v-btn
                :outlined="mode.type !== ProducerType.PRODUCER.id"
                block
                color="primary"
                @click="selectType(ProducerType.PRODUCER.id)"
              >
                <span>Produtor</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="5">
              <v-btn
                :outlined="mode.type !== ProducerType.GROUP.id"
                block
                color="primary"
                @click="selectType(ProducerType.GROUP.id)"
              >
                <span>Grupo de Produtores</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col cols="12">
              <span>{{
                $t(
                  "text.informativo_apos_definir_up_produtor_grupo_para_convite"
                )
              }}</span>
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col cols="12">
              <h2 class="primary-text--text">
                {{ $t(mode.titleSelect) }}
              </h2>
            </v-col>
          </v-row>

          <v-row class="ma-0" v-if="mode.type">
            <v-col cols="10">
              <v-text-field
                @input="debounceInput"
                v-bind:value="searchInput"
                outlined
                :placeholder="$t(mode.searchPlaceholder)"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            v-if="canSeeTable"
            :items="items"
            :headers="mode.headers"
            hide-default-footer
            :items-per-page="1000"
          >
            <template v-slot:[`item.id`]="{ item }">
              <btn-link @click="handleSelectProducerOrGroup(item)"
                >Selecionar</btn-link
              >
            </template>

            <template v-slot:[`body.append`]="{ headers }">
              <tr>
                <td :colspan="headers.length" style="height: 0">
                  <infinite-loading spinner="waveDots" @infinite="loadMore">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                  </infinite-loading>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-row class="ma-0" v-if="canSeeDataNotFound">
            <v-col cols="12">
              <p class="secondary-text--text">
                {{ $t(mode.dataNotFound, { tipo: mode.label }) }}
              </p>
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-center align-center">
          <v-btn text color="primary" @click="state.activate = false">{{
            $t("labels.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { ProducerType } from "@/modules/core/enums/producer-type.enum";
import BtnLink from "@/modules/core/views/components/BtnLink";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  components: {
    BtnLink
  },
  data: () => ({
    ProducerType: ProducerType,

    searchInput: "",
    page: 1,
    limit: 15,
    next: null,
    loadingByInfiniteLoading: false,

    mode: {
      type: null,
      titleSelect: null
    },
    types: {
      [ProducerType.PRODUCER.id]: {
        type: ProducerType.PRODUCER.id,
        titleSelect: "text.titulo_selecione_produtor",
        searchPlaceholder: "labels.search_producer",
        dataNotFound: "text.nenhum_registro_encontrado_por_tipo",
        label: "produtor",
        headers: [
          {
            text: "Produtor/Grupo",
            align: "start",
            value: "nome"
          },
          { text: "CPF/CNPJ", value: "cpf_cnpj" },
          { text: "E-mail", value: "email" },
          { value: "id" }
        ]
      },
      [ProducerType.GROUP.id]: {
        type: ProducerType.GROUP.id,
        titleSelect: "text.titulo_selecione_grupo_produtor",
        searchPlaceholder: "labels.search_producer_group",
        dataNotFound: "text.nenhum_registro_encontrado_por_tipo",
        label: "grupo de produtores",
        headers: [
          {
            text: "Grupo de Produtores",
            align: "start",
            value: "nome"
          },
          { text: "Composição", value: "composicao" },
          { value: "id" }
        ]
      }
    },
    items: []
  }),
  watch: {
    async searchInput() {
      if (!this.hasSearchText()) {
        this.items = [];
        return;
      }
      this.page = 1;
      this.items = await this.fetchData();
    }
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading"
    }),
    canSeeTable() {
      return (
        this.hasSearchText() &&
        (this.loadingByInfiniteLoading || !this.isLoading) &&
        this.items.length
      );
    },
    canSeeDataNotFound() {
      return (
        this.hasSearchText() &&
        (this.loadingByInfiniteLoading || !this.isLoading) &&
        !this.items?.length
      );
    }
  },
  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("invite", ["getProducers", "getProducersGroups"]),

    hasSearchText(searchValue) {
      return !!this.searchInput && !!this.searchInput.length;
    },
    selectType(type) {
      if (
        !Object.values(ProducerType).some(
          producerType => producerType.id === type
        )
      ) {
        throw new Error("Invalid selected type");
      }
      this.items = [];
      this.searchInput = "";
      this.mode = this.types[type];
    },

    debounceInput: debounce(async function(value) {
      this.searchInput = value;
    }, 800),

    handleSelectProducerOrGroup(producerOrGroup) {
      this.$emit("producerOrGroupSelected", {
        ...producerOrGroup,
        tipo: this.mode.type
      });
      this.state.activate = false;
    },

    async fetchData() {
      if (this.mode.type === ProducerType.PRODUCER.id) {
        return await this.fetchProducers();
      }
      return await this.fetchProducersGroup();
    },

    async fetchProducers() {
      return await this.callFetchData(async () => {
        const { results, next } = await this.getProducers({
          buscar: this.searchInput,
          page: this.page,
          limit: this.limit
        });
        this.next = next;
        return results;
      });
    },

    async fetchProducersGroup() {
      return await this.callFetchData(async () => {
        const { results, next } = await this.getProducersGroups({
          nome: this.searchInput,
          page: this.page,
          limit: this.limit
        });
        this.next = next;
        return results;
      });
    },

    async callFetchData(fn) {
      try {
        return await fn();
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    async loadMore($state) {
      if (!this.next) {
        $state.complete();
        return;
      }

      try {
        this.loadingByInfiniteLoading = true;
        this.page++;
        const data = await this.fetchData();
        this.items = [...this.items, ...data];
      } catch (error) {
        this.next = false;
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      } finally {
        this.loadingByInfiniteLoading = false;
        $state.loaded();
      }
    }
  }
};
</script>

<style></style>
