<template>
  <div>
    <h1 class="mb-4 page-title">Novo Convite</h1>
    <v-stepper v-model="currentStep">
      <v-stepper-header>
        <template v-for="(step, n) in steps">
          <v-stepper-step
            :key="step.step"
            :complete="currentStep > step.step"
            :step="step.step"
            color="#185080"
          >
            {{ step.label }}
          </v-stepper-step>
          <v-divider
            v-if="n < 2"
            :key="`step-divider-${step.step}`"
          ></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <StepperContent
          v-if="currentStep === 1"
          :step="1"
          :max="3"
          @onNext="nextStep"
          @onPrevious="previousStep"
        >
          <v-alert class="mt-3" type="info">
            {{ $t("text.informativo_safra") }}
          </v-alert>
          <select-system @onSelectSystems="setSystems"></select-system>
        </StepperContent>
        <StepperContent
          v-if="currentStep === 2"
          :step="2"
          :max="3"
          @onNext="nextStep"
          @onPrevious="previousStep"
          :nextBtnDisabled="
            hasPreviousSbrhviResponsible && hasSelectedSystemSBRHVI
          "
        >
          <Invites :tabState="tabState" :systems="systems" />
        </StepperContent>

        <StepperContent
          v-if="currentStep === 3"
          :step="3"
          :max="3"
          @onNext="nextStep"
          @onPrevious="previousStep"
        >
          <confirmation />
        </StepperContent>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import SelectSystem from "../components/SelectSystem";
import Invites from "../components/Invites";
import StepperContent from "@/modules/core/views/components/StepperContent";
import Confirmation from "../components/Confirmation";
import text from "../components/text";

import { isValidPredicate } from "@/modules/core/helpers/validPredicate";

export default {
  components: {
    SelectSystem,
    StepperContent,
    Invites,
    Confirmation
  },

  data: () => ({
    text: text,
    currentStep: 1,
    tabState: {
      currentTab: 0
    },
    steps: [
      {
        step: 1,
        label: "Selecionar programas"
      },
      {
        step: 2,
        label: "Convite"
      },
      {
        step: 3,
        label: "Enviar Convite"
      }
    ]
  }),
  computed: {
    ...mapGetters({
      systems: "invite/getSystems",
      newInvite: "invite/getNewInvite",
      hasPreviousSbrhviResponsible: "invite/getHasSbrHviResponsible",
      exemptSbrhvi: "invite/getExemptSbrhvi",
      getProducerOrGroup: "invite/getProducerOrGroup",
      selectedSystems: "invite/getSystems"
    }),
    hasSelectedSystemSBRHVI() {
      return this.systems.length === 1;
    },
    tabs() {
      if (this.hasSelectedSystemSBRHVI) {
        return {
          SBRHVI: 0
        };
      }
      return {
        ABRBCI: 0,
        SBRHVI: 1
      };
    }
  },
  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("invite", ["setSystems", "resetNewInvite", "saveInvite"]),
    async save() {
      try {
        const payload = {
          produtor_id: this.newInvite.produtor_id,
          tipo_produtor: this.newInvite.tipo_produtor
        };

        const hasSelectAbrAndExemptSbrhvi =
          this.exemptSbrhvi && this.selectedSystems.length === 2;

        if (
          !this.hasPreviousSbrhviResponsible &&
          !hasSelectAbrAndExemptSbrhvi
        ) {
          payload.sbrhvi = {
            convidado: _.pickBy(
              this.newInvite.sbrhvi.convidado,
              isValidPredicate
            )
          };
        }

        if (this.newInvite.abr.convidados.length) {
          payload.abr = {
            convidados: this.newInvite.abr.convidados.map(convidado =>
              _.pickBy(convidado, isValidPredicate)
            ),
            unidades_produtivas: this.newInvite.abr.unidades_produtivas.map(
              un => un.id
            )
          };
        }

        const data = await this.saveInvite(payload);
        if (data) {
          this.toggleSnackbar({
            text: "Convite enviado com sucesso!",
            type: "success"
          });
          this.resetNewInvite();
          return this.$router.push("/");
        }
      } catch (e) {
        this.toggleSnackbar({
          text: "Erro ao salvar o convite!",
          type: "error"
        });
      }
    },
    validateSecondStep() {
      const haveSBRHVI = this.systems.some(
        system => (system.name + "").toUpperCase() === "SBRHVI"
      );
      const producer_group = this.getProducerOrGroup;
      if (producer_group.habilitar_convite_sbrhvi && haveSBRHVI) {
        if (!this.newInvite.produtor_id) {
          this.tabState.currentTab = this.hasSelectedSystemSBRHVI
            ? this.tabs.SBRHVI
            : this.tabs.ABRBCI;

          this.toggleSnackbar({
            text: "Por favor, selecione um produtor ou grupo de produtores!",
            type: "info"
          });
          return false;
        }
        if (
          !this.hasPreviousSbrhviResponsible &&
          !this.newInvite.sbrhvi.convidado.nome
        ) {
          this.tabState.currentTab = this.tabs.SBRHVI;
          this.toggleSnackbar({
            text: "Por favor, selecione um usuário para o sistema SBRHVI!",
            type: "info"
          });
          return false;
        }
      }
      const haveABR = this.systems.some(system => system.name === "ABR/BCI");
      if (haveABR) {
        if (!this.newInvite.abr.unidades_produtivas.length) {
          this.tabState.currentTab = this.tabs.ABRBCI;
          this.toggleSnackbar({
            text: "Por favor, selecione pelo menos uma unidade produtiva!",
            type: "info"
          });
          return false;
        }
        if (!this.newInvite.abr.convidados.length) {
          this.tabState.currentTab = this.tabs.ABRBCI;
          this.toggleSnackbar({
            text:
              "Por favor, selecione pelo menos um usuário para o Sistema ABR/BCI!",
            type: "info"
          });
          return false;
        }
      }
      return true;
    },
    async nextStep(event) {
      if (event === 2 && !this.systems.length) {
        return;
      }
      if (event === 3 && !this.validateSecondStep()) {
        return;
      }
      if (event === 4) {
        // Save data
        return this.save();
      }
      this.currentStep = event;
    },
    previousStep(step) {
      if (step === 0) {
        // Clear state
        this.resetNewInvite();
        return this.$router.push("/");
      }
      if (step === 1) {
        this.resetNewInvite();
      }
      this.currentStep = step;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styleguide";
.page-title {
  color: $primary-color-1;
}

::v-deep .v-stepper__wrapper {
  width: 100% !important;
}

::v-deep .v-stepper__header {
  box-shadow: none;
  border-bottom: 1px solid #ccc;
}
</style>
