<template>
  <div class="mt-4">
    <p class="section-subtitle">
      {{
        $t(
          "text.confirmar_unidades_produtivas_pertencentes_produtor_grupo_para_convite"
        )
      }}
    </p>
    <v-row
      v-if="!productiveUnits.length"
      class="d-flex justify-center align-center mt-4 mb-4"
    >
      <p class="secondary-text--text">
        {{
          $t("text.nenhum_registro_encontrado", {
            nome: $t("labels.unity_productive")
          })
        }}
      </p>
    </v-row>
    <v-data-table
      v-if="productiveUnits.length"
      :hide-default-footer="true"
      :headers="headers"
      :items="productiveUnits"
      :items-per-page="100"
      class="mb-8 bordered-table"
      :class="{ 'table-delimiter': useTableDelimiter }"
      :no-data-text="$t('text.nenhum_registro_selecionado_tabelas')"
    >
      <template v-slot:[`item.safra_certificacao`]="{ item }">
        {{
          item.safra_certificacao
            ? item.safra_certificacao
            : $t("text.field_not_found")
        }}
      </template>
      <template v-slot:[`item.acao`]="{ item }">
        <div class="d-flex flex-wrap">
          <v-tooltip
            max-width="488"
            top
            :disabled="
              !harvestCertificationDisabled ||
                !item.pode_alterar_safra_certificacao
            "
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  :disabled="
                    harvestCertificationDisabled ||
                      !item.pode_alterar_safra_certificacao
                  "
                  class="ma-2"
                  outlined
                  small
                  color="primary"
                  @click="selectHarvestCertification(item)"
                >
                  {{ $t("labels.edit_harvest_certification") }}
                </v-btn>
              </div>
            </template>
            <span>{{
              $t("text.informativo_editar_safra_certificacao_dados_invalidos")
            }}</span>
          </v-tooltip>

          <v-tooltip
            max-width="488"
            top
            :disabled="hasSelectedHarvestCertification(item)"
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  class="ma-2"
                  outlined
                  small
                  :disabled="!hasSelectedHarvestCertification(item)"
                  :color="isSelected(item) ? 'error' : 'primary'"
                  @click="manageUnit(item)"
                >
                  {{
                    isSelected(item)
                      ? $t("buttons.cancel_selection")
                      : $t("buttons.confirm_selection_up")
                  }}
                </v-btn>
              </div>
            </template>
            <span>{{
              $t("text.informativo_selecionar_up_dados_invalidos")
            }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.matriculas`]="{ item }">
        <registrations :values="item.matriculas" :maxLength="3" />
      </template>
    </v-data-table>
    <dialog-edit-harvest-certification
      :state="dialogEditHarvestCertificationState"
      @harvestCertificationUpdated="handleHarvestCertificationUpdated"
      :key="dialogEditHarvestCertificationStateKey"
    />
  </div>
</template>

<script>
import Registrations from "@/modules/invite/views/components/Registrations";
import DialogEditHarvestCertification from "@/modules/inviteds/views/components/DialogEditHarvestCertification";

import { mapActions, mapGetters } from "vuex";
import { uniqBy } from "lodash";

export default {
  props: {
    useTableDelimiter: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    Registrations,
    DialogEditHarvestCertification
  },
  data: () => ({
    dialogEditHarvestCertificationStateKey: 1,
    dialogEditHarvestCertificationState: {
      activate: false
    }
  }),

  watch: {
    "dialogEditHarvestCertificationState.activate": function(newVal) {
      if (newVal) {
        this.dialogEditHarvestCertificationStateKey++;
      }
    }
  },

  computed: {
    ...mapGetters({
      selectedProductiveUnits: "invite/getSelectedProductiveUnits",
      productiveUnits: "invite/getProducerProductiveUnits",
      newInvite: "invite/getNewInvite",
      producerOrGroup: "invite/getProducerOrGroup"
    }),
    headers() {
      return [
        { text: "ID", value: "id" },
        { text: this.$t("labels.unity_productive"), value: "nome_propriedade" },
        { text: this.$t("labels.city"), value: "cidade" },
        {
          text: this.$t("labels.harvest_certification"),
          value: "safra_certificacao"
        },
        { text: this.$tc("labels.registration", 2), value: "matriculas" },
        { text: "Ação", value: "acao" }
      ];
    },
    harvestCertificationDisabled() {
      return !this.producerOrGroup?.safra_certificacao_limite;
    }
  },

  methods: {
    ...mapActions("invite", [
      "addProductiveUnit",
      "removeProductiveUnit",
      "getProductiveUnits",
      "setProducerOrGroup"
    ]),
    ...mapActions(["toggleSnackbar"]),
    isSelected(productiveUnit) {
      return this.selectedProductiveUnits.some(
        unit => unit.id === productiveUnit.id
      );
    },
    manageUnit(productiveUnit) {
      const isSelected = this.isSelected(productiveUnit);
      if (isSelected) {
        return this.removeProductiveUnit(productiveUnit);
      }

      return this.addProductiveUnit(productiveUnit);
    },
    selectHarvestCertification(productiveUnit) {
      this.openEditHarvestCertification(productiveUnit);
    },
    openEditHarvestCertification(productiveUnit) {
      this.dialogEditHarvestCertificationState = {
        producerOrGroup: { ...this.producerOrGroup },
        productiveUnit,
        activate: true
      };
    },
    async handleHarvestCertificationUpdated() {
      this.dialogEditHarvestCertificationState.activate = false;
      try {
        await this.setProducerOrGroup({
          tipo: this.producerOrGroup.tipo,
          id: this.producerOrGroup.id
        });
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },
    hasSelectedHarvestCertification(productiveUnit) {
      return !!productiveUnit.safra_certificacao;
    }
  }
};
</script>

<style scoped>
.bordered-table {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.table-delimiter {
  max-height: 600px;
  overflow: auto;
}
</style>
