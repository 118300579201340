<template>
  <div>
    <v-tabs v-model="tabState.currentTab" grow>
      <v-tab v-for="system in systems" :key="system.name">{{
        system.name
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabState.currentTab">
      <v-tab-item v-if="systems.length > 1" class="wrapper">
        <tab-abr-bci />
      </v-tab-item>
      <v-tab-item class="wrapper">
        <tab-sbr-hvi :showSelectProducerOrGroup="systems.length === 1" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TabAbrBci from "@/modules/invite/views/components/TabAbrBci";
import TabSbrHvi from "@/modules/invite/views/components/TabSbrHvi";

export default {
  components: {
    TabAbrBci,
    TabSbrHvi
  },
  props: ["systems", "tabState"]
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding-bottom: 40px;
}
</style>
