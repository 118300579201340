<template>
  <v-tooltip max-width="488" top color="black" :disabled="tooltipDisabled">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">{{ minText }}</span>
    </template>
    <span>
      {{ formattedText }}
    </span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    values: {
      type: Array,
      required: true
    },
    maxLength: {
      type: Number,
      required: true
    }
  },
  computed: {
    canFit() {
      return this.values?.length <= this.maxLength;
    },
    tooltipDisabled() {
      return this.canFit;
    },
    minText() {
      const text = this.values.slice(0, this.maxLength).join(", ");
      if (!this.canFit) {
        return text.concat("... ");
      }
      return text;
    },
    formattedText() {
      return this.values?.join(", ");
    }
  }
};
</script>
<style lang="scss" scoped></style>
